// import CustomColorTextField from "../custom_field/CustomColorTextField";
import {
  buildProperty,
  buildSchema,
  ExportMappingFunction,
} from "@camberi/firecms";
import { BlogEntryPreview } from "../schema/BlogEntryPreview";
import { blogTypes } from "../types/blogs";

export const blogSchema = buildSchema<blogTypes>({
  name: "Blogs",
  views: [
    {
      path: "preview",
      name: "Preview",
      builder: (props) => <BlogEntryPreview {...props} />,
    },
  ],
  properties: {
    title: buildProperty({
      title: "Title",
      validation: { required: true },
      dataType: "string",
    }),
    authorName: buildProperty({
      title: "Author Name",
      validation: { required: true },
      dataType: "string",
    }),
    header_image: buildProperty({
      title: "Header image",
      dataType: "string",
      path: "header_image",
      preview: true,
      config: {
        storageMeta: {
          mediaType: "image",
          storagePath: "images",
          storeUrl: true,
          acceptedFiles: ["image/*"],
          metadata: {
            cacheControl: "max-age=1000000",
          },
        },
      },
    }),
    // status: buildProperty(({ values }) => ({
    //   title: "Status",
    //   validation: { required: true },
    //   dataType: "string",
    //   columnWidth: 140,
    //   config: {
    //     enumValues: {
    //       published: {
    //         label: "Published",
    //         disabled: !values.header_image,
    //       },
    //       draft: "Draft",
    //     },
    //   },
    // })),
 
    content: buildProperty({
      title: "Content",
      description:
        "Example of a complex array with multiple properties as children",
      validation: { required: true },
      dataType: "array",
      columnWidth: 400,
      oneOf: {
        typeField: "type",
        valueField: "value",
        properties: {
          // images: {
          //   title: "Images",
          //   dataType: "array",
          //   of: {
          //     dataType: "string",
          //     config: {
          //       storageMeta: {
          //         mediaType: "image",
          //         storagePath: "images",
          //         acceptedFiles: ["image/*"],
          //         metadata: {
          //           cacheControl: "max-age=1000000",
          //         },
          //       },
          //     },
          //   },
          //   description:
          //     "This fields allows uploading multiple images at once and reordering",
          // },
          text: {
            dataType: "string",
            title: "Text",
            config: {
              markdown: true,
            },
          },
          // products: {
          //   title: "Products",
          //   dataType: "array",
          //   of: {
          //     dataType: "reference",
          //     path: "products",
          //     previewProperties: ["name", "main_image"],
          //   },
          // },
        },
      },
    }),
    Date: {
      title: "Date",
      dataType: "timestamp",
      autoValue: "on_create",
    },
    // publish_date: buildProperty({
    //   title: "Publish date",
    //   dataType: "timestamp",
    // }),
    // reviewed: buildProperty({
    //   title: "Reviewed",
    //   dataType: "boolean",
    // }),
    // tags: {
    //   title: "Tags",
    //   description: "Example of generic array",
    //   dataType: "array",
    //   of: {
    //     dataType: "string",
    //     config: {
    //       previewAsTag: true,
    //     },
    //   },
    // },
  },
  // defaultValues: {
  //   status: "draft",
  //   tags: ["default tag"],
  // },
});

/**
 * Sample field that will be added to the export
 */
export const sampleAdditionalExportColumn: ExportMappingFunction = {
  key: "extra",
  builder: async ({ entity }) => {
    await new Promise((resolve) => setTimeout(resolve, 100));
    return "Additional exported value " + entity.id;
  },
};
