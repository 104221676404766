// Staging
// export const firebaseConfig = {
//   apiKey: "AIzaSyBvFx_hSC3xYnu6Fpsocz7PblnwLToM0iM",
//   authDomain: "annex-staging-73565.firebaseapp.com",
//   databaseURL: "https://annex-staging-73565-default-rtdb.firebaseio.com",
//   projectId: "annex-staging-73565",
//   storageBucket: "annex-staging-73565.appspot.com",
//   messagingSenderId: "873740129981",
//   appId: "1:873740129981:web:cd82397d9bd4f94fd5c9d4"
// };

// Production
 export const firebaseConfig = {
  apiKey: "AIzaSyBBlgaE4_AJmBf4OkRzT4-Uc5f_pbaZhB8",
  authDomain: "annex-6c150.firebaseapp.com",
  databaseURL: "https://annex-6c150-default-rtdb.firebaseio.com",
  projectId: "annex-6c150",
  storageBucket: "annex-6c150.appspot.com",
  messagingSenderId: "176991723560",
  appId: "1:176991723560:web:736084665b00940684951d",
  measurementId: "G-MBM454W567"
};

